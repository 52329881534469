import classNames from "classnames"
import React from "react"
import { MarketingFeaturesEntity } from "../../../entities/PageEntity"
import { As } from "../../general/As/As"
import { Video } from "../../general/Video/Video"

export const Features: React.FC<
  MarketingFeaturesEntity & { invert?: boolean }
> = (props) => {
  const [selected, setSelected] = React.useState(0)

  return (
    <div className="py-16 bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="px-6 md:px-0 lg:pr-4 max-w-5xl">
          <As
            component={props.label.component}
            className=" relative font-semibold text-2xl tracking-tight inline-block"
          >
            <span className="absolute left-0 bottom-0 w-full h-3/4 bg-pink-200 bg-opacity-50 rounded -skew-x-12 -z-0" />
            <span className="relative">{props.label.value}</span>
          </As>

          <As
            component={props.title.component}
            className=" text-2xl mt-4 font-semibold tracking-tight sm:text-5xl"
          >
            {props.title.value}
          </As>

          <As
            component={"p"}
            className="mt-4 lg:max-w-3xl text-xl tracking-tight  text-slate-700"
          >
            {props.description}
          </As>
        </div>

        <div className="grid grid-cols-1 gap-x-8 lg:grid-cols-2 lg:items-start mt-8">
          <div className={classNames(props.invert && "order-last")}>
            <dl className="mx-auto mt-6 max-w-2xl  text-base lg:mx-0 lg:max-w-lg">
              {props.features?.map((feature, index) => (
                <div
                  key={index}
                  onClick={() => setSelected(index)}
                  className={classNames(
                    index === selected
                      ? "block bg-pink-50"
                      : "hover:bg-pink-50",
                    "relative my-2 flex cursor-pointer items-start justify-center rounded-lg border border-transparent p-4 transition-all duration-300 ease-in-out"
                  )}
                  role="tab"
                  aria-selected={index === selected}
                  aria-controls={`feature-panel-${index}`}
                  id={`feature-tab-${index}`}
                >
                  <span
                    // @ts-ignore
                    className="mr-3 font-semibold text-pink-300"
                    aria-hidden="true"
                  >
                    0{index + 1}
                  </span>
                  <div>
                    <dt className="flex items-center  font-semibold text-slate-900">
                      <h3 aria-labelledby={`feature-title-${index}`}>
                        {feature.title.value}
                      </h3>
                    </dt>
                    <dd
                      id={`feature-desc-${index}`}
                      aria-describedby={`feature-title-${index}`}
                      className="inline"
                    >
                      {feature.description}
                    </dd>
                  </div>
                </div>
              ))}
            </dl>
          </div>
          <div
            className="mx-auto flex h-full items-start px-2 lg:mx-0 lg:w-full lg:pt-6"
            role="tabpanel"
            id={`feature-panel-${selected}`}
            aria-labelledby={`feature-tab-${selected}`}
          >
            {props.features
              ?.filter((_, index) => index === selected)
              .map((feature) => (
                <Video
                  key={feature.video.src?.publicURL}
                  src={feature.video.src?.publicURL}
                  illustration={feature.video.illustration}
                  alt={feature.video?.alt || ""}
                  autoplay
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
