import React, { ReactNode } from "react"

export const Title: React.FC<{
  title: ReactNode
}> = (props) => {
  return (
    <div className="mx-auto flex max-w-3xl mb-4 px-4">
      <h2 className="text-3xl md:text-5xl font-semibold leading-tight tracking-tighter ">
        {props.title}
      </h2>
    </div>
  )
}
