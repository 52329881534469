import React from "react"
import { Button } from "../../UI/Button/Button"
import {
  connector,
  ContainerProps,
} from "./containers/authenticate-button.tsx.containers"

export const Wrapper: React.FC<ContainerProps> = (props) => {
  return (
    <Button variant="secondary" onClick={props.onClick}>
      {props.children}
    </Button>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const AuthenticateButton = connector(Container)
