import classNames from "classnames"
import { AlertTriangleIcon, CheckIcon, InfoIcon } from "lucide-react"
import React, { useEffect } from "react"
import { ITranslations } from "../../../interfaces/ITranslations"
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage"
import { SearchGptSubscriptionSuccessModal } from "../../search-gpt/search-gpt-subscription-success-modal/search-gpt-subscription-success-modal"
import { ButtonSecondary } from "../../UI/Button/Button"
import { Tooltip } from "../../UI/Tooltip"
import {
  connector,
  ContainerProps,
} from "./containers/pricing-search-gpt.containers"

const features: Array<{
  label: ITranslations["keys"]
  legend?: ITranslations["keys"]
}> = [
  {
    label: "search-gpt/landing/pricing/features/discord",
  },
  {
    label: "search-gpt/landing/pricing/features/suggest-features",
  },
  {
    label: "search-gpt/landing/pricing/features/priority-access",
  },
  {
    label: "search-gpt/landing/pricing/features/free-access",
    legend: "search-gpt/landing/pricing/features/free-access-description",
  },
  {
    label: "search-gpt/landing/pricing/features/satisfy-or-refund",
    legend: "search-gpt/landing/pricing/features/satisfy-or-refund-description",
  },
]

export const Wrapper: React.FC<ContainerProps> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  return (
    <>
      <div className="flex mt-8 mb-4 justify-center" id="pricing">
        <div className="flex relative items-center font-medium text-red-500 gap-x-2 rounded-full bg-red-50 px-4 py-2">
          <div className="w-4 h-1 bg-red-50 rounded-full absolute -left-8 top-0 rotate-[30deg]"></div>
          <div className="w-4 h-1 bg-red-50 rounded-full absolute -left-10 top-0 bottom-0 my-auto"></div>
          <div className="w-4 h-1 bg-red-50 rounded-full absolute -left-8 bottom-0 -rotate-[30deg]"></div>
          <div className="w-4 h-1 bg-red-50 rounded-full absolute -right-8 top-0 -rotate-[30deg]"></div>
          <div className="w-4 h-1 bg-red-50 rounded-full absolute -right-10 top-0 bottom-0 my-auto"></div>
          <div className="w-4 h-1 bg-red-50 rounded-full absolute -right-8 bottom-0 rotate-[30deg]"></div>
          <AlertTriangleIcon className="h-4 w-4 " />
          <FormattedMessage
            id="search-gpt/landing/pricing/badge"
            values={{
              d: props.seats,
            }}
          />
        </div>
      </div>

      <div
        className={classNames(
          "relative flex flex-col justify-between px-4 mx-auto max-w-3xl rounded-3xl bg-white "
        )}
      >
        <div>
          <div className="font-semibold text-pink-400 text-lg">
            <FormattedMessage id="search-gpt/landing/pricing/label" />
          </div>
          <div className="flex mt-2 items-center font-semibold tracking-tight">
            <div className="relative  inline-block">
              <span className="text-3xl text-slate-300 line-through leading-none">
                {props.price + 100}€
              </span>
            </div>
            <span className="ml-2 leading-none text-6xl">{props.price}€</span>
            <div className="text-slate-400 ml-2">
              <div className="leading-none">
                <FormattedMessage id="search-gpt/landing/pricing/vat" />
              </div>
              <div className="leading-none text-slate-300">
                <FormattedMessage id="search-gpt/landing/pricing/vat-description" />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <FormattedMessage
              id="search-gpt/landing/pricing/description"
              values={{
                d: Math.ceil(props.price / 12) + "€",
              }}
            />
          </div>

          <div className="mt-8">
            <ButtonSecondary
              onClick={props.onPay}
              size="lg"
              fullWidth
              className="!shadow-slate-900 shadow-btn-2 !border-2 !border-slate-900"
            >
              <FormattedMessage id="search-gpt/landing/pricing/buy-button" />
            </ButtonSecondary>
          </div>

          <ul role="list" className="mt-8 space-y-4">
            {features.map((feature, featureIndex) => (
              <div key={featureIndex}>
                <li key={featureIndex} className={"flex font-medium"}>
                  <div className="flex h-6 w-6 rounded-full bg-pink-100 items-center justify-center">
                    <CheckIcon
                      className="h-4 w-4 flex-none text-pink-400"
                      aria-hidden="true"
                    />
                  </div>

                  <div className="ml-3 flex items-center">
                    <span>
                      <FormattedMessage id={feature.label} />
                    </span>

                    {feature.legend && (
                      <Tooltip
                        label={<FormattedMessage id={feature.legend} />}
                        direction="bottom"
                        align="center"
                        tooltipClassName="max-w-lg"
                      >
                        <div className="ml-1">
                          <InfoIcon className="h-4 w-4 flex-none text-slate-400" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>

      <SearchGptSubscriptionSuccessModal />
    </>
  )
}

const Connected: React.FC<ContainerProps> = (props) => <Wrapper {...props} />
export const PricingSearchGpt = connector(Connected)
