import React from "react"

export function Testimonials() {
  return (
    <div className="relative isolate mt-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mt-4">
          <div
            className="senja-embed"
            data-id="b1a93db4-f24b-4114-9160-93eaa150645d"
            data-lazyload="false"
          ></div>
        </div>
      </div>
    </div>
  )
}
