import { connect, ConnectedProps } from "react-redux"
import { MarketingHeroEntity } from "../../../../entities/PageEntity"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (
  state: RootState,
  props: MarketingHeroEntity & {
    nbUsers: number
    hideUsers?: boolean
  }
) => ({
  ...props,
  authenticated: state.auth.authenticated,
})

const mapDispatch = (dispatch: any) => ({
  onAuthenticate: () => {
    dispatch(actions.auth.$goToAuthentication())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
