import { StarHalfIcon, StarIcon } from "lucide-react"
import React from "react"
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage"

const commenters = [
  {
    image:
      "https://senjaio.b-cdn.net/public/media/022MvnhvcIv4GKNSVxA0jgPy.jpeg?width=100&height=100",
  },
  {
    image:
      "https://senjaio.b-cdn.net/public/media/gK5esfpSIHhVecSvVs7qmDU4.jpeg?width=100&height=100",
  },
  {
    image:
      "https://senjaio.b-cdn.net/public/media/MITMFjhUfgdltHDLJTHslP8L.jpeg?width=100&height=100",
  },
  {
    image:
      "https://senjaio.b-cdn.net/public/media/ys6AdIrdb3dJvXE3kA8Rp16Y.jpeg?width=100&height=100",
  },
  {
    image:
      "https://senjaio.b-cdn.net/public/media/vy0Jhv98lP24mEeeoZYnSFs5.jpeg?width=100&height=100",
  },
]

export const UsersAndStars = (props: { nbUsers: number }) => {
  return (
    <div className="mt-8 flex justify-center items-center">
      <div className="ml-4 flex -space-x-2">
        {commenters.map((commenter) => (
          <dd key={commenter.image}>
            <img
              className="h-10 w-10 object-cover object-center rounded-full bg-slate-50 ring-2 ring-white"
              src={commenter.image}
              alt={""}
            />
          </dd>
        ))}
      </div>

      <div className="ml-4">
        <div className="flex  text-sm items-center">
          <StarIcon className="h-5 w-5 text-yellow-500  fill-yellow-400" />
          <StarIcon className="h-5 w-5 text-yellow-500 fill-yellow-400" />
          <StarIcon className="h-5 w-5 text-yellow-500 fill-yellow-400" />
          <StarIcon className="h-5 w-5 text-yellow-500 fill-yellow-400" />
          <StarHalfIcon className="h-5 w-5 text-yellow-500 fill-yellow-400" />
          4,7/5
        </div>
        <div className="">
          <FormattedMessage
            id="home/hero/used-by"
            values={{
              nbusers: props.nbUsers,
            }}
          />{" "}
        </div>
      </div>
    </div>
  )
}
