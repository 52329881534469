import React from "react"
import ReactMarkdown from "react-markdown"
import { MarketingHeroEntity } from "../../../entities/PageEntity"
import { As } from "../../general/As/As"
import { Container } from "../../UI/Container"
import { connector, ContainerProps } from "./containers/Hero.containers"

export const Wrapper: React.FC<MarketingHeroEntity> = (props) => {
  return (
    <div className="relative bg-white">
      <div
        className="hidden xl:block absolute right-0 top-32 animate-bounce"
        style={{ animationDuration: "4s" }}
      >
        <div className="h-36 w-36 rounded-full bg-blue-100 bg-opacity-50"></div>
      </div>

      <div
        className="absolute hidden xl:block left-0 top-32 animate-bounce"
        style={{ animationDuration: "3s" }}
      >
        <div className="h-36 w-36 rotate-12 rounded-lg bg-red-100 bg-opacity-50"></div>
      </div>

      <Container className="flex flex-col items-center mb-8 justify-center text-center">
        {props.label && (
          <As
            component={props.label.component}
            className="text-center  md:text-lg lg:text-xl tracking-tighter font-medium text-slate-400"
          >
            {props.label.value}
          </As>
        )}

        <As
          component={props.title.component}
          className="mx-auto block max-w-6xl flex-col  text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold leading-tight tracking-tighter text-slate-900 "
        >
          <ReactMarkdown
            components={{
              p: ({ children }) => <>{children}</>,
              em: ({ children }) => (
                <>
                  <br />
                  {children}
                </>
              ),
              strong: ({ children }) => (
                <span className="relative">
                  <span className="absolute left-0 bottom-0 w-full h-3/4 bg-pink-200 bg-opacity-50 rounded -rotate-1 -skew-x-12 -z-0" />
                  <span className="relative">{children}</span>
                </span>
              ),
            }}
          >
            {props.title.value}
          </ReactMarkdown>
        </As>

        {props.description && (
          <ReactMarkdown
            className={
              "mx-auto mt-4 md:mt-8 max-w-xl md:max-w-5xl md:text-xl lg:text-xl leading-normal tracking-tight text-slate-400"
            }
            components={{
              p: ({ children }) => (
                <p className="md:block inline">{children}</p>
              ),
            }}
          >
            {props.description}
          </ReactMarkdown>
        )}
      </Container>
    </div>
  )
}

export const ContainerRedux: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Hero = connector(ContainerRedux)
