import React from "react"

import { MarketingButtonsEntity } from "../../../entities/PageEntity"
import { AuthenticateButton } from "../../general/authenticate-button.tsx/authenticate-button"
import { FoudroyerLink } from "../../general/FoudroyerLink/FoudroyerLink"
import { Button } from "../../UI/Button/Button"

export const MarketingButtons: React.FC<MarketingButtonsEntity> = (props) => {
  return (
    <div className="flex justify-center items-center flex-col gap-4">
      {props.buttons.map((button) => {
        if (button.type === "authentication") {
          return (
            <AuthenticateButton key={button.label}>
              {button.label}
            </AuthenticateButton>
          )
        }

        return (
          <FoudroyerLink key={button.label} to={button.url || "#"}>
            <Button
              variant={button.variant}
              size={button.size}
              key={button.label}
            >
              {button.label}
            </Button>
          </FoudroyerLink>
        )
      })}
    </div>
  )
}
