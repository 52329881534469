import React from "react"
import { AnalyticsPricing } from "../components/analytics/AnalyticsPricing/AnalyticsPricing"
import { Navbar } from "../components/general/Navbar/Navbar"
import { Notifications } from "../components/general/Notifications/Notifications"
import { Seo } from "../components/general/Seo/Seo"
import { Video } from "../components/general/Video/Video"
import { AnnouncementBadge } from "../components/marketing/announcement-badge/announcement-badge"
import { Author } from "../components/marketing/Author/Author"
import { MarketingButtons } from "../components/marketing/buttons/buttons"
import { Faq } from "../components/marketing/Faq/Faq"
import { Features } from "../components/marketing/Features/Features"
import { Footer } from "../components/marketing/Footer"
import { Hero } from "../components/marketing/Hero/Hero"
import { PricingSearchGpt } from "../components/marketing/pricing-search-gpt/pricing-search-gpt"

import { PricingLanding } from "../components/marketing/Pricing/Pricing"
import { ProductOfTheDay } from "../components/marketing/product-of-the-day/product-of-the-day"
import { SimpleFeatures } from "../components/marketing/simple-features/simple-features"
import { Testimonials } from "../components/marketing/Testimonials/Testimonials"
import { Text } from "../components/marketing/Text/Text"
import { Title } from "../components/marketing/Title/Title"
import { UsersAndStars } from "../components/marketing/users-and-stars/users-and-stars"
import { PremiumModal } from "../components/payment/UpsellConfirmationModal/UpsellConfirmationModal"

function Page(props) {
  let simpleFeaturesIndex = 1

  return (
    <div className="antialiased text-slate-900">
      <Seo
        title={props.pageContext.meta.title}
        description={props.pageContext.meta.description}
        lang={props.pageContext.langKey}
        langUrls={[]}
      />

      <Navbar />

      <main>
        {props.pageContext.content.map((content, index) => {
          if (content.type === "marketing/announcement-badge")
            return <AnnouncementBadge key={index} {...content} />

          if (content.type === "marketing/product-of-the-day")
            return <ProductOfTheDay key={index} />

          if (content.type === "marketing/users-and-stars")
            return <UsersAndStars key={index} {...content} />

          if (content.type === "marketing/simple-features")
            return (
              <SimpleFeatures
                key={index}
                {...content}
                index={simpleFeaturesIndex++}
              />
            )

          if (content.type === "marketing/buttons")
            return <MarketingButtons key={index} {...content} />

          if (content.type === "marketing/hero")
            return <Hero key={index} {...content} />

          if (content.type === "marketing/title")
            return <Title key={index} title={content.title.value} />

          if (content.type === "marketing/text")
            return <Text key={index} value={content.value} />

          if (content.type === "marketing/video")
            return (
              <Video
                key={index}
                src={content.src.publicURL}
                illustration={content.illustration}
                alt={content.alt}
                autoplay={content.autoplay}
              />
            )

          if (content.type === "marketing/author")
            return (
              <Author
                key={index}
                illustration={content.illustration}
                name={content.name}
              />
            )

          if (content.type === "marketing/pricing") {
            if (content.scope === "analytics")
              return <AnalyticsPricing key={index} />
            if (content.scope === "search-gpt")
              return <PricingSearchGpt key={index} />
            return <PricingLanding key={index} />
          }

          if (content.type === "marketing/features")
            return (
              <Features {...content} key={index} invert={!Boolean(index % 2)} />
            )

          if (content.type === "marketing/testimonials")
            return <Testimonials key={index} />

          if (content.type === "marketing/faq")
            return <Faq key={index} {...content} />

          return content.type
        })}
      </main>

      <PremiumModal />
      <Notifications />
      <Footer />
    </div>
  )
}

export default Page
