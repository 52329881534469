import React from "react"
import { Container } from "../../UI/Container"

const ObfuscatedLink = (props: {
  href: string
  target: string
  children: React.ReactNode
}) => {
  return (
    <span
      className="cursor-pointer"
      onClick={() => window.open(props.href, props.target)}
    >
      {props.children}
    </span>
  )
}

export const ProductOfTheDay = () => {
  return (
    <Container className="flex my-8 flex-col items-center justify-center text-center">
      <ObfuscatedLink
        href="https://www.producthunt.com/posts/foudroyer-2?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-foudroyer&#0045;2"
        target="_blank"
      >
        <img
          src="https://s3.producthunt.com/static/badges/daily1.svg"
          className="w-36"
        />
      </ObfuscatedLink>
    </Container>
  )
}
