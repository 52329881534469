import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState) => ({
  price: state.payments.searchGPTPreorderPrice,
  seats: state.payments.searchGPTPreorderSeats,
})

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.payments.$SearchGPTPreorderFetchPrice())
  },
  onPay() {
    dispatch(actions.payments.$SearchGptPay())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
